import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});


	// GSAP scrollreveal

	// Elevator pitch line under title
	gsap.to(".elevator-pitch h2 span", {
		scrollTrigger: {
			trigger: ".elevator-pitch h2 span",
			start: "0% 70%"
		},
		"--dynamic-width": "100%", duration: 3, ease: Expo.easeOut}
	);

	// Navigation blocks
	gsap.from(".navigation-block__image-wrapper", {
		scrollTrigger: {
			trigger: ".navigation-block__image-wrapper",
			start: "20% 80%",
		},
		"opacity": "0", scale: 1.05, duration: 1.5}		
	);

	// Realisaties grid
	gsap.from(".image-wrapper__inner", {
		scrollTrigger: {
			trigger: ".image-wrapper__inner",
			start: "20% 80%",
		},
		scale: 1.1, duration: 1.5, stagger: 0.1}		
	);

	
	gsap.from(".work", {
		scrollTrigger: {
			trigger: ".work",
			start: "20% 80%",		
		},
		opacity: 0, duration: 1.5, stagger: 0.1}		
	);

	// Call to action scroll reveal
	gsap.from(".call-to-action", {
		scrollTrigger: {
			trigger: ".call-to-action",
			start: "top 100%",
			end: "bottom bottom",
			scrub: true			
		},
		opacity: 0, y: 75, duration: 4}		
	);
	

});